import { isNotNullOrUndefined, isNullOrUndefinedOrEmpty } from 'in-time-core';

export function isRunningInWebView(userAgent: string): boolean {
  if(isNullOrUndefinedOrEmpty(userAgent)) {
    return false;
  }

  const lowerCaseUserAgent = userAgent.toLowerCase();
  return lowerCaseUserAgent.includes('fban') || lowerCaseUserAgent.includes('fbav') || lowerCaseUserAgent.includes('instagram') || lowerCaseUserAgent.includes('wv');
}

export function tryToFindUserAgent(): string | null {
  if(typeof window === 'undefined') {
    return null;
  }

  let userAgent = window?.navigator?.userAgent;
  if(isNullOrUndefinedOrEmpty(userAgent)) {
    userAgent = navigator?.userAgent;
  }

  if(isNullOrUndefinedOrEmpty(userAgent)) {
    userAgent = window?.navigator?.vendor;
  }
  if(isNullOrUndefinedOrEmpty(userAgent)) {
    userAgent = navigator?.vendor;
  }

  if(isNullOrUndefinedOrEmpty(userAgent)) {
    userAgent = (window as any).opera;
  }

  return isNullOrUndefinedOrEmpty(userAgent) ? null : userAgent;
}

export function tryToFindUserAgentVerbose(): { userAgent: string, source: string } | null {
  if(typeof window === 'undefined') {
    return null;
  }

  let output: { userAgent: string | null, source: string } = {
    userAgent: window?.navigator?.userAgent ?? null,
    source: 'window.navigator.userAgent'
  };

  if(isNullOrUndefinedOrEmpty(output.userAgent)) {
    output = { userAgent: navigator?.userAgent ?? null, source: 'navigator.userAgent' };
  }

  if(isNullOrUndefinedOrEmpty(output.userAgent)) {
    output = { userAgent: window?.navigator?.vendor ?? null, source: 'window.navigator.vendor' };
  }
  if(isNullOrUndefinedOrEmpty(output.userAgent)) {
    output = { userAgent: navigator?.vendor ?? null, source: 'navigator.vendor' };
  }

  if(isNullOrUndefinedOrEmpty(output.userAgent)) {
    output = { userAgent: (window as any).opera ?? null, source: 'window.opera' };
  }

  return isNullOrUndefinedOrEmpty(output.userAgent) ? null : { userAgent: output.userAgent, source: output.source };
}

export function isUserAgentMobile(): boolean {
  if(typeof window === 'undefined') {
    return false;
  }

  const userAgent = tryToFindUserAgent();
  if(isNotNullOrUndefined(userAgent)) {
    return /Mobi/.test(userAgent);
  }

  return false;
}